// @codekit-prepend "jquery.cookie.js";
// @codekit-prepend "lazysizes.min.js";
// @codekit-prepend "slick.min.js";
$(window).on('load resize', function(){
    if ($(window).width() <= 899){
        $('body').removeClass('desktop').addClass('mobile');
        $('nav#nav .inner .follow').detach().appendTo('.navigation');
    } else {
        $('body').removeClass('mobile').addClass('desktop');
        $('.navigation .follow').detach().appendTo('nav#nav .inner');
    }
});
$(document).ready(function () {

    $('.trailer-overlay').show();
    $('nav#nav').hide();

    if ($.cookie('elementHidden') === 'true') {
      $('.trailer-overlay').hide();
      $('nav#nav').show();
    }
    $('.trailer-overlay .close').on('click', function() {
      $('nav#nav').show();
      $('#trailer').trigger('pause');
      $('#lttrailer').remove();
        $('.trailer-overlay').toggle();
        if ($('.trailer-overlay').is(':hidden')) {
            $.cookie('elementHidden', 'true', { expires: 14 });
        } else {
            $.removeCookie('elementHidden');
            $('nav#nav').show();
        }
    });
    $('#removeCookieButton').on('click', function() {
        $.removeCookie('elementHidden');
        $('.trailer-overlay').show();
        $('nav#nav').hide();
        $('#trailer').trigger('play');
        $('.trailer-overlay .play-btn').hide();
    });

    $('.trailer-overlay .play-btn').on('click', function() {
      $('#trailer').trigger('play');
      $(this).hide();
    });

    let aud = document.getElementById("trailer");
    aud.onended = function() {
      $('.trailer-overlay').hide();
      $('nav#nav').show();
    };
      
    $("img,iframe").each(function() {
        var $this = $(this);
        var src = $this.attr("src");
        $this.attr( "data-src", src );
        $this.removeAttr("src");
        $(this).addClass("lazyload")
      }); 
    $('#site').append('<div class="overlay hidden"></div>');
    $('#site').append('<div class="tothetop hidden"></div>');
    $('.login-box input#user_login').attr('placeholder','E-mail');
    $('.login-box input#user_pass').attr('placeholder','Password');
    $('.login-box input.button.button-primary').val('login');
    $("nav#nav .toggle").on('touch click', function(){
        $("nav#nav .navigation,.overlay").toggleClass('hidden');
        $("nav#nav .toggle").toggleClass('active');
    });
    $(".bx.bx-log-in-circle").on('touch click', function(){
        $(".login-box,.overlay").removeClass('hidden');
    });
    $(".overlay").on('touch click', function(){
        $("nav#nav .navigation,.overlay,.login-box").addClass('hidden');
        $("nav#nav .toggle").removeClass('active');
    });
    $('nav#nav ul.menu').find('li:has(ul)').children('a').removeAttr('href').addClass("submenu");
    $('nav#nav ul.menu li a.submenu').on('touch click', function(){
        $(this).toggleClass("active").closest('li').find('ul').toggleClass('active');
    });
    $("nav#nav .navigation ul.menu").prepend('<div class="nav-header">Navigation</div>');
    $('nav#nav .navigation ul.menu li a.submenu').append('<span></span>');
    $("#site").scroll(function(){
        if ($(this).scrollTop() > 250) {
            $('nav#nav').addClass('sticky');
            $('.tothetop').removeClass('hidden');
        } else {
            $('nav#nav').removeClass('sticky');
            $('.tothetop').addClass('hidden');
        }
    });
    $(".tothetop").on('touch click', function(){
        $('#site').animate({scrollTop : 0},100);
	    return false;
    });
    //$('ul.sheet-music li.sheet').wrap('<div class="holder"></div>');
    $('ul.sheet-music').slick({
        accessibility: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }
        ]
    });
 });